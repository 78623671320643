@import '../../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import 'variables';

body {
    font-family: $font-family-sans-serif;
    font-size: .9rem;
}
button,
.lang-switch {
    display: none;
}

svg {
    max-width: 50px;
    max-height: 50px;

    path {
        fill: $color-primary;
    }
}

.main-content {
    min-height: 100%;
}

header {
    .container {
        overflow: hidden;
        min-height: auto;
    }

    .logo {
        display: block;
        max-width: 200px;
        margin: 0 auto;
    }

    .title {
        display: none;
    }
}

.sticky-region {
    position: relative;
    margin: 0;
    background-color: white;

    .page-status-wrapper {
        position: relative;
        max-width: 500px;
        margin: 0 auto;
    }

    .page-status {
        position: relative;
        text-align: center;
        margin: $grid-gutter-width auto;
        width: auto;
        padding: $spacer;
        padding-bottom: 0;

        h3 {
            color: $color-primary;
            font-size: $font-size-sm;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-bottom: $grid-gutter-width;
        }

        .selected-line {
            font-size: $font-size-sm;
            font-weight: 500;
            margin-bottom: $spacer;

            span {
                text-align: center;
                display: block;
            }

            span.value {
                font-weight: $font-weight-bold;
            }
        }
    }

    .page-status-head {
        text-align: center;
        padding: 0;
        padding-bottom: calc($spacer * 2);
    }
}

.verdict-wrapper {
    order: 2;
    width: 100%;
}
.stats-wrapper {
    order: 1;
    width: 100%;
}

.stats-view {
    text-align: center;
    margin-top: $grid-gutter-width - $spacer;
    margin-bottom: $grid-gutter-width;
    padding-top: $grid-gutter-width*1.5;
    padding-bottom: $grid-gutter-width;
    border-top: 2px solid $color-primary;
    border-bottom: 2px solid $color-primary;

    .stats-title {
        font-size: $font-size-xs;
        font-weight: 500;
    }
    .primary {
        color: $color-hl1;
        font-size: 4rem;
        font-weight: 700;
    }

    .stats-issue {
        font-size: $font-size-xs;
        margin-bottom: calc($spacer / 2);
        text-align: center;
    }
}

.form-question h3 {
    display: none;
}
.outro {
    margin-top: 1rem;
    border: 1px solid $color-border-light;
    border-radius: 5px;
    padding: .5rem;
}

.col-lg-9 {
    width: 100%;
}
.offset-lg-3 {
    margin-left: 0;
}
